<template>
  <BaseModal
    name="modal-upsell"
    size="lg"
    title="Implementação Upsell"
    @shown="openModal"
  >
    <div v-if="loading">
      Carregando...
    </div>
    
    <div id="modal-content" v-else>
      <div class="mod">
        <h3>Selecione o tipo de botão</h3>
        <p>Insira o botão de compra em qualquer lugar da sua página HTML. Fique a vontade para customizá-lo!</p>
        <b-row>
          <b-col>
            <b-form-group label="Selecione o tipo">
              <b-form-select v-model="type" :options="types"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Ativar OneClickBuy">
              <b-form-checkbox 
                v-model="isOneClickBuy"
                name="one_click_buy"
                switch
              >
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col>
          <div>
            <legend class="bv-no-focus-ring col-form-label pt-0">Preview</legend>
            <div class="btn-preview" :data-type="type" v-html="getCode()"></div>
          </div>
        </b-col>
        <b-col>
          <legend class="bv-no-focus-ring col-form-label pt-0">
            Código HTML
          </legend>
          <div class="code">{{ getCode() }}</div>
          <button class="btn-copy" v-on:click="copyCode(getCode())">Copiar</button>
        </b-col>
      </b-row>

      <div class="mod">
        <h3>Script Upsell</h3>
        <p>Insira o script de compra upsell em qualquer lugar da sua página de HTML.</p>

        <div class="script">
          <div class="code">
            &lt;script&gt; (function (w, d, s, t) { if (w._voompUp) return;
            w._voompUp = t; var f = d.getElementsByTagName(s)[0], j =
            d.createElement(s); j.async = true; j.src = "{{ getUpsellURL() }}?v=" + t;
            f.parentNode.insertBefore(j, f); })(window, document, "script",
            Date.now()); &lt;/script&gt;
          </div>
          <button class="btn-copy" v-on:click="copyCode(getUpsellScript())">Copiar</button>
        </div>
      </div>

    </div>
      <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="closeModal(cancel)">
        {{ $t("seller.upsell.ModalInfo.text_2826") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
export default {
  props: ["dataModal"],
  data() {
    return {
      id: null,
      data: {},
      error: false,
      metas: {},
      loading: true,
      type: "custom",
      isOneClickBuy: false,
      isShowNotBuy: false,
      isSwitchCheckNewPayment: false,
      types: [
        { value: "simple", text: "Botão simples" },
        { value: "custom", text: "Botão personalizado" },
      ],
    };
  },
  methods: {
    getUpsellURL(){
      return `${process.env.VUE_SALES_PAGE}/assets/upsell.js`;
    },

    getUpsellScript(){
      return document
        .querySelector(".script .code")
        .innerHTML
        .replaceAll("&lt;", "<")
        .replaceAll("&gt;", ">");
    },

    getCode() {
      if (this.loading === true) {
        return "Carregando...";
      }

      const attrID = `data-voomp-upsell="${this.id}"`;
      const attrOnClick = `data-voomp-one-click="${
        this.isOneClickBuy ? "true" : "false"
      }"`;

      switch (this.type) {
        case "simple":
          return `<button ${attrID} ${attrOnClick} disabled>Comprar</button>`;

        case "custom":
          const styleBtn = [
            "cursor: pointer",
            this.metas.background ? `background: ${this.metas.background}` : '',
            this.metas.border_weight ? `border: ${this.metas.border_weight}${this.metas.medida} solid ${this.metas.border_color}` : '',
            this.metas.border_radius ? `border-radius: ${this.metas.border_radius}${this.metas.medida}` : '',
            this.metas.color ? `color: ${this.metas.color}` : '',
            this.metas.txt_size ? `font-size: ${this.metas.txt_size}${this.metas.medida}` : '',
            this.metas.width ? `width: ${this.metas.width}${this.metas.medida}` : '',
            this.metas.height ? `height: ${this.metas.height}${this.metas.medida}` : '',
            this.metas.font_style ? `font-style: ${this.metas.font_style}` : '',
            this.metas.font_weight ? `font-weight: ${this.metas.font_weight}` : '',
          ];


          const styleNotBuy = [
            "cursor: pointer",
            "display: flex",
            "font-family: Montserrat",
            "justify-content: center",
            this.metas.txt_color_not_buy ? `color: ${this.metas.txt_color_not_buy}` : '',
            this.metas.font_size_not_buy ? `font-size: ${this.metas.font_size_not_buy}${this.metas.medida}` : '',
            this.metas.width ? `width: ${this.metas.width}${this.metas.medida}` : '',
            this.metas.not_buy_height ? `height: ${this.metas.not_buy_height}${this.metas.medida}` : '',
            this.metas.not_buy_font_style ? `font-style: ${this.metas.not_buy_font_style}` : '',
            this.metas.not_buy_font_weight ? `font-weight: ${this.metas.not_buy_font_weight}` : '',
          ];

          const attrNotBuy = [
            'id="not-buy-link"',
            `data-voomp-not-buy-link="${this.id}"`,
            `href="${this.metas.link_not_buy}"`,
            `style="${styleNotBuy.filter(i => i !== '').join("; ")}"`,
          ].join(" ");

          const attrSwitchCheckNewPayment = [
            `href="#"`,
            `data-voomp-new-payment="${this.id}"`,
            `style="${styleNotBuy.filter(i => i !== '').join("; ")}"`,
          ].join(" ");

          const attrStyle = `style="${styleBtn.filter(i => i !== '').join("; ")}"`;
          const attrBtn = `${attrID} ${attrOnClick} ${attrStyle} disabled`;
          const textNotBuy = this.metas.text_btn_not_buy;

          return `
            <button ${attrBtn}>${this.metas.txt_btn}</button><br />
            ${this.isSwitchCheckNewPayment ? `<a ${attrSwitchCheckNewPayment}>Escolher outro método de pagamento</a>` : ""}
            ${this.isShowNotBuy ? `<a ${attrNotBuy}>${textNotBuy}</a>` : ""}
          `;
      }
    },

    closeModal(cb) {
      this.loading = true;

      setTimeout(() => {
        cb();
      }, 100);
    },

    openModal() {
      this.id = this.dataModal.id;
      this.loading = true,
      this.data = {},
      this.error = false,
      this.metas = {},
      this.type = "custom",
      this.isOneClickBuy = false,
      this.isShowNotBuy = false,
      
      this.loadUpsell();
    },

    async loadUpsell() {
      this.loading == true;
      
      const response = await fetch(
        `${process.env.VUE_APP_API_HOST}/api/upsell/${this.id}/metas`
      );

      if (response.ok) {
        this.data = await response.json();

        this.data.metas.forEach((m) => {
          switch (m.upsell_key) {
            case "txt_style":
              JSON.parse(m.upsell_value).forEach((prop) => {
                const key = prop === "italic" ? "font_style" : "font_weight";
                this.metas[key] = prop;
              });
              break;

            case "txt_style_not_buy":
              JSON.parse(m.upsell_value).forEach((prop) => {
                const key =
                  prop === "italic"
                    ? "not_buy_font_style"
                    : "not_buy_font_weight";
                this.metas[key] = prop;
              });
              break;

            case "switch_check_not_buy":
              this.isShowNotBuy = m.upsell_value === '1';
            break;

            case "switch_check_new_payment":
              this.isSwitchCheckNewPayment = m.upsell_value === '1';
            break;

            default:
              this.metas[m.upsell_key] = m.upsell_value;
          }

          return;
        });

        this.loading = false;

        return;
      }

      this.error = true;
    },

    async copyCode(code) {
      await navigator.clipboard.writeText(code);

      this.$bvToast.toast(this.$t("seller.upsell.ModalInfo.text_866"), {
        titl: this.$t("seller.upsell.ModalInfo.text_2827"),
        variant: "success",
        autoHideDelay: 1000,
        appendToast: true,
      });
    },
  },
};
</script>

<style lang="scss">

.mod {
  &:last-of-type{
    margin-top: 30px;
  }

  h3 {
    font-size: 17px;
    padding-bottom: 5px;
    border-bottom: 1px solid #999;
  }

  p {
    font-size: 14px;
  }
}

.code {
  background: #000;
  color: white;
  height: 100px;
  align-items: center;
  padding: 15px;
  font-size: 12px;
  border-radius: 5px 5px 0 0;
  border: none;
  display: block;
  overflow-y: scroll;
}

.btn-copy {
  &:hover {
    background: #eee;
  }

  border: none;
  display: block;
  padding: 15px;
  border: none;
  color: #333;
  background: #ddd;
  outline: none !important;
  border-radius: 0 0 5px 5px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  font-weight: 500;
}

.btn-preview {
  border: 1px solid #999;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 352px;
  overflow: auto;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;

  &[data-type="simple"] {
    button {
      background-color: #ccc;
      color: #000;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: block;
      font-size: 14px;
      padding: 10px 10px;
      border: 1px solid #bbb;
      width: 70%;
      cursor: pointer;
    }
  }
}

.script {
  display: flex;

  .code{
    height: 120px;
    border-radius: 5px 0 0 5px;
  }

  .btn-copy{
    border-radius: 0 5px 5px 0;
    width: 100px
  }
}
</style>
