const Money = {
    methods: {
        formatMoney(number, decPlaces = 2, decSep = ',', thouSep = '.') {
            if (typeof number !== 'undefined' && number !== null && number !== '') {
                // Formata o número com o máximo de casas decimais
                let formattedNumber = parseFloat(number).toFixed(decPlaces);

                // Adiciona o separador de milhares e o separador decimal
                let parts = formattedNumber.split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thouSep);
                return parts.join(decSep);
            }
            return '0' + decSep + '00';
        },
    }
};

export default Money;
